<template>
  <div class="wrap">
    <div id="gdList">
      <div class="gdItem" v-for="(item, index) in tableData" :key="index">
        <div class="imgCont">
          <img :src="item.img" :key="item.id" />
        </div>

        <div class="payParams fx">
          <span class="font24 clRed"> {{ item.jifen }} </span>
          <span class="lText clRed font12"> 积分 </span>
          <!---->
        </div>

        <div class="fx mt5 mb5">
          <span class="beyond_ellipsis2 title"
            ><!---->
            {{ item.title }}
          </span>
        </div>
        <div class="btn_duihuan">
          <el-button class="btn" @click="redact(item)">立即兑换</el-button>
        </div>
      </div>
    </div>

    <el-dialog width="150" title="兑换" :visible.sync="dialogFormVisible">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="订单信息" name="essentialInformation">
          <el-form :model="formamend">
            <el-form-item label="商品名称：" label-width="120">
              {{ formamend.title }}
            </el-form-item>
            <el-form-item label="商品图片：" label-width="120">
              <img style="width: 100px" :src="formamend.img" />
            </el-form-item>
            <el-form-item label="当前积分：" label-width="120">
              {{ integral }}
            </el-form-item>

            <el-form-item label="所需积分：" label-width="120">
              {{ formamend.jifen }}
            </el-form-item>

            <el-form-item label="姓名：" label-width="120">
              <el-input type="text" v-model="formamend.name"></el-input>
            </el-form-item>
            <el-form-item label="电话：" label-width="120">
              <el-input type="text" v-model="formamend.mobile"></el-input>
            </el-form-item>
            <el-form-item label="收货地址：" label-width="120">
              <el-input type="text" v-model="formamend.address"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="btn" @click="amend">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get_goods, add_order } from "@/api/hr24_api/api_all.js";
import { showCity } from "@/api/resume";
import axios from "axios";

export default {
  data() {
    return {
      search_str: "",
      search_sel: [
        { label: "用户", value: "username" },
        { label: "公司", value: "license_company_name" },
      ],
      select_index: "",

      tableData: [],
      // 编辑
      indry: [],
      city: [],
      is_seed: false,
      dialogFormVisible: false,
      formamend: {
        g_id: "",
        title: "",
        img: "",
        jifen: "",
        name: "",
        mobile: "",
        address: "",
      },
      place_data: [],
      industry_name: "",
      user_detail_id: "",
      form: {},
      activeName: "essentialInformation",
      integral: 0,
    };
  },
  mounted() {
    // axios.get("/static/industry.json").then((res) => {
    //   this.indry = res;
    // });
    // showCity({}).then((res) => {
    //   console.log("dddddddddd showCity");
    //   this.city = res.data;
    // });
    this.get_data();
  },
  methods: {
    getTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    onSubmit() {
      console.log("dddddddddd onSubmit");
      let obj = {
        company_user: this.search_str,
        vip_end_time: this.getTime(this.form.date),
        is_seed: this.form.is_seed == true ? 1 : 0,
      };

      amend_date(obj).then((res) => {
        console.log("dddddddddd amend_date");
        if (!res.code) {
          this.dialogFormVisible = false;
          this.$message.success(res.msg);
          this.search(1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(val) {
      console.log(val);
    },
    areaChange(e) {
      console.log(e);
    },
    indusChange(e) {
      console.log(e);
    },
    get_data() {
      console.log("get_data");

      get_goods({}).then((res) => {
        if (!res.code) {
          console.log("ssssssssssss");
          console.log(res.data);

          this.integral = res.integral;

          res.data.map((item) => {
            console.log(item);
            // item.img = "../../static/goods/" + item.img;
          });

          this.tableData = res.data;
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        console.log(res);
      });
    },

    redact(e) {
      console.log("sssssssssssss");
      this.activeName = "essentialInformation";
      this.dialogFormVisible = true;
      this.formamend = {
        g_id: e.id,
        title: e.title,
        img: e.img,
        jifen: e.jifen,
      };

      console.log(this.formamend);
    },

    amend() {
      console.log("提交订单");

      if (this.activeName == "essentialInformation") {
        this.formamend;

        console.log("name：" + this.formamend.name);

        if (
          typeof this.formamend.name === "undefined" ||
          typeof this.formamend.mobile === "undefined" ||
          typeof this.formamend.address === "undefined"
        ) {
          this.$message.error("请把信息填写完整！");
          return;
        }
        let obj = {
          g_id: this.formamend.g_id,
          name: this.formamend.name,
          mobile: this.formamend.mobile,
          address: this.formamend.address,
        };

        add_order(obj).then((res) => {
          if (!res.code) {
            this.dialogFormVisible = false;
            this.$message.success(res.msg);
            this.search(1);
          } else {
            this.$message.error(res.msg);
          }
        });
        return false;
      } else if (this.activeName == "MemberExpiration") {
        this.onSubmit();
        return false;
      } else {
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: 30%;
  margin-right: 10px;
}
.el-select > .el-input {
  width: 100px !important;
}
.el-input__inner {
  width: 100px !important;
}

#gdList {
  width: 1210px;
  padding-bottom: 10px;
  margin: 0 auto;
}

#gdList .gdItem {
  width: 292px;
  height: 410px;
  border-radius: 10px;
  background: #fff;
  padding: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 10px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
}

#gdList .gdItem .imgCont {
  height: 260px;
}

#gdList .gdItem .imgCont img {
  width: 100%;
  height: 100%;
}

img {
  border: 0;
  outline: none;
  vertical-align: middle;
}

#gdList .gdItem .smallImg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0;
  cursor: pointer;
}
#gdList .gdItem .smallImg div:last-child {
  margin-right: 0;
}
#gdList .gdItem .smallImg div {
  width: 30px;
  height: 30px;
  margin-right: 4px;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
}

#gdList .gdItem .payParams {
  -webkit-box-align: baseline;
  align-items: baseline;
  margin-top: 10px;
}
.fx {
  display: flex;
}

.font24 {
  font-size: 24px;
}
.clRed {
  color: #e5008d;
}

#gdList .gdItem .payParams .lText {
  position: relative;
  bottom: 2px;
  margin-left: 2px;
}
.font12 {
  font-size: 12px;
}
.clRed {
  color: #e5008d;
}

#gdList .gdItem .title {
  line-height: 40px;
}
.beyond_ellipsis2 {
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  line-height: 1.3em;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  line-clamp: 2;
}
.btn_duihuan {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
</style>
