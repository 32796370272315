import { require } from '././../../utils/http'
//注册管理
function registerMana(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/register_list/', obj)
}
//公司展示jd详情
function search_resume(obj) {
  return require('post', '/api/kapin_ee/user/v1/user_search/', obj)
}

//删除企业
function mydelete(obj) {
  return require('post', '/api/kapin_ee/user/v1/mydelete/', obj)
}
//上传营业执照
function upfile(obj) {
  return require('post', '/api/kapin_ee/user/v1/upfile/', obj)
}





// 海报 视频列表
function poster_list(obj) {
  return require('post', '/api/kapin_ee/share/v1/poster_list/', obj)
}
// 海报添加多张
function poster_upload(poster_list) {
  let obj = {
    file_id_list: poster_list,
  }
  return require('post', '/api/kapin_ee/share/v1/create_more_posters/', obj)
}
// 视频添加多张
function create_video(obj) {
  return require('post', '/api/kapin_ee/share/v1/create_posters/', obj)
}
// 海报删除
function poster_del(poster) {
  let obj = {
    poster_id: poster,
  }
  return require('post', '/api/kapin_ee/share/v1/delete_posters/', obj)
}
// 一键还原密码
function restore(user) {
  let obj = {
    username: user,
  }
  return require('post', '/api/kapin_ee_admin/auth/v1/restore_password/', obj)
}

// 删除简历
function delete_resume(resume) {
  let obj = {
    phone: resume,
  }
  return require('post', '/api/kapin_ee_admin/auth/v1/delete_mongo_resume/', obj)
}

// 修改会员到期时间
function amend_date(obj) {
  return require('post', '/api/kapin_ee/user/v1/change_vip_end_time/', obj)
}
// 修改用户查询
function amend_user(obj) {
  return require('post', '/api/kapin_ee/user/v1/company_update/', obj)
}
// 修改用户帐号
function change_user(obj) {
  return require('post', '/api/kapin_ee/user/v1/change_user_info/', obj)
}
// 根据条件进行注册管理筛选
function filter_regis(obj) {
  return require('post', '/api/kapin_ee_admin/auth/v1/register_list/', obj)
}
// 创建帐号
function create_user(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee_admin/auth/v1/create/', obj)
}

//  后台管理 导入excel文件
function upload_resume(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee_admin/auth/v1/excel_resume/', obj)
}

//  后台管理 导入excel文件列表
function show_excel(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee_admin/auth/v1/show_excel/', obj)
}

//  后台管理 导出简历手机号
function daochu_phone(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee_admin/auth/v1/daochu_phone/', obj)
}

//  后台管理 注册审核
function audit(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/audit/', obj)
}

//  后台管理 创建帐号 代理商城市列表
function show_agent_city(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee_admin/auth/v1/show_agent_city/', obj)
}



// 增加积分
function add_jifen(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/add_jifen/', obj)
}

// 查询积分
function search_jifen(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/search_jifen/', obj)
}

// 兑换积分
function update_jifen(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/update_jifen/', obj)
}

// 查询所有兑换商品
function get_goods(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/get_goods/', obj)
}

// 添加兑换订单
function add_order(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/add_order/', obj)
}

//  订单 审核及发货
function update_order(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/update_order/', obj)
}

//  查询商品兑换订单
function search_order(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/search_order/', obj)
}

//  获取订单详情
function get_order_user(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/get_order_user/', obj)
}

//  添加或更新兑换产品信息
function update_goods(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/update_goods/', obj)
}
//  删除产品信息
function delete_goods(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/delete_goods/', obj)
}

//  刷新简历
function shuaxin_resume(obj) {
  console.log(obj)
  return require('post', '/api/resume/v1/shuaxin_resume/', obj)
}

//  导出职位
function daochu_jd(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/tool/v1/daochu_jd/', obj)
}

// 查询积分
function search_zhifu(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/search_zhifu/', obj)
}

// 查询积分
function delete_zhifu(obj) {
  console.log(obj)
  return require('post', '/api/kapin_ee/user/v1/delete_zhifu/', obj)
}




export {
  filter_regis,
  create_user,
  amend_date,
  change_user,
  amend_user,
  registerMana,
  search_resume,
  delete_resume,
  poster_list,
  poster_upload,
  poster_del,
  restore,
  create_video,
  upload_resume,
  show_excel,
  audit,
  show_agent_city,
  mydelete, upfile,
  add_jifen,
  search_jifen,
  update_jifen,
  get_goods,
  add_order,
  update_order,
  search_order,
  get_order_user,
  update_goods,
  delete_goods,
  daochu_phone,
  shuaxin_resume,
  daochu_jd,
  search_zhifu,
  delete_zhifu
}
